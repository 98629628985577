import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import { fetchAPIData, GenDocFileDownload } from "../../api/AuthService"
import { DOWNLOAD_LINK, POA_REFDOC_DOWNLOAD } from "../../constant/Constants"
import CustomLink from "./CustomLink"

const ComRefDocDownload = (props) => {
   let data = []
   data = props.data
   const headerContent = props.headerContent

   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState(false)
   const [errorMeg, setErrorMsg] = useState(false)

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)

      return keys.map((key, index) => {
         if (
            key.toLowerCase() === "id" ||
            key.toLowerCase() === "link" ||
            key.toLowerCase() === "filepath" ||
            key.toLowerCase() === "gendoc" ||
            key.toLowerCase() === "file name" ||
            key.toLowerCase() === "isirm" ||
            key.toLowerCase() === "format" ||
            key.toLowerCase() === "isgendoc"
         ) {
            return null
         }
         if (key.toLowerCase() === "zcsurl") {
            return <th key={key}></th>
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] !== "" && props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         let isGendoc = props.data["isGendoc"] ? props.data["isGendoc"] : ""
         if (props.data[key] != null && typeof props.data[key] === "object") {
            var colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            (key.toLowerCase() === "name" || key.toLowerCase() === "document name") &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            props.data["isIRM"] &&
            (props.data["isIRM"].toLowerCase().trim() === "true" || isGendoc === "true")
         ) {
            var name = props.data[key]
            return (
               <td style={{ minWidth: "9rem" }} key={index}>
                  {props.data["link"] && props.data["link"] !== "" && props.data["id"] ? (
                     <CustomLink
                        id={props.data["id"]}
                        link={props.data["link"]}
                        name={name}
                     />
                  ) : (
                     <span>{name}</span>
                  )}
               </td>
            )
         }
         if (key.toLowerCase() === "zcsurl") {
            //check for access
            if (props.data["isIRM"] === "true" || isGendoc === "true") {
               let isAccess = true
               if (
                  (props.data["Originator"] &&
                     props.data["Originator"].toLowerCase().trim() === "no access") ||
                  (props.data["id"] &&
                     props.data["id"].toLowerCase().trim() === "no access") ||
                  props.data["link"] === "PQR"
               ) {
                  isAccess = false
               }

               return (
                  <td key={index}>
                     {isAccess && props.data["id"] ? (
                        <button onClick={() => downloadFile(props.data["id"].trim())}>
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                     ) : (
                        <button disabled>
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                     )}
                  </td>
               )
            } else {
               let isAccess = true
               if (
                  (props.data["id"] &&
                     props.data["id"].toLowerCase().trim() === "no access") ||
                  (props.data["link"] && props.data["link"] === "PQR")
               ) {
                  isAccess = false
               }

               let fileName = props.data["File Name"]
                  ? props.data["File Name"]
                  : props.data["Name"]

               return (
                  <td key={index}>
                     {isAccess &&
                     props.data[key] &&
                     fileName &&
                     props.data["filepath"] ? (
                        <button
                           onClick={() =>
                              downloadGenDocFiles(
                                 fileName.trim(),
                                 props.data["filepath"].trim(),
                                 props.data["Format"] ? props.data["Format"] : "",
                                 props.data["Title"] ? props.data["Title"].trim() : ""
                              )
                           }
                        >
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                     ) : (
                        <button disabled>
                           <i className="fa fa-download" aria-hidden="true"></i>
                        </button>
                     )}
                  </td>
               )
            }
         } else if (
            key.toLowerCase() === "id" ||
            key.toLowerCase() === "link" ||
            key.toLowerCase() === "filepath" ||
            key.toLowerCase() === "gendoc" ||
            key.toLowerCase() === "file name" ||
            key.toLowerCase() === "isirm" ||
            key.toLowerCase() === "format" ||
            key.toLowerCase() === "isgendoc"
         ) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const downloadFile = (object_ID) => {
      var fileName = "download"
      setLoading(true)

      fetchAPIData(`${DOWNLOAD_LINK}${object_ID}`, { responseType: "blob" })
         .then(function (response) {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               fileName = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg("No file available to download.Please contact SpecReaderExt.im@pg.com for help.")
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else {
               setShow(true)
               setErrorMsg("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
               console.log("FCS Error Response:-", error)
            }
            setLoading(false)
         })
   }
   const downloadGenDocFiles = (fileName, filePath, filetype, Title) => {
      var downloadFilename = "download"
      setLoading(true)

      let specialRegex = /[`!@#$%^&*()+={};':"\\|<>?~]/

      headerContent &&
         Object.entries(headerContent).forEach(
            ([key, val]) =>
               val &&
               typeof val !== "object" &&
               specialRegex.test(val) &&
               delete headerContent[key]
         )

      GenDocFileDownload(
         `${POA_REFDOC_DOWNLOAD}`,
         fileName,
         filePath,
         filetype,
         {},
         headerContent,
         Title
      )
         .then((response) => {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               downloadFilename = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])

            saveAs(fileContents, downloadFilename)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg(`"${fileName} is not available to download.Please contact SpecReaderExt.im@pg.com for help."`)
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else {
               setShow(true)
               setErrorMsg("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
               console.log("FCS Error Response:-", error)
            }
            setLoading(false)
         })
   }

   if (show) {
      return (
         <Alert
            style={{ textAlign: "center", marginLeft: "8rem", marginRight: "8rem" }}
            variant="info"
            onClose={() => setShow(false)}
            dismissible
         >
            <p>{errorMeg}</p>
         </Alert>
      )
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <LoadingOverlay active={loading} spinner text="Downloading....">
               <table
               >
                  <thead>
                     <tr>{getHeader(data[0])}</tr>
                  </thead>
                  <tbody>{getRowsData(data)}</tbody>
               </table>
            </LoadingOverlay>
         )}
      </div>
   )
}
export default ComRefDocDownload
