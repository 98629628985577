import React, { useState } from "react"
import { saveAs } from "file-saver"
import { Table, Alert } from "react-bootstrap"
import CustomLink from "../commonComponents/CustomLink"
import LoadingOverlay from "react-loading-overlay"
import { setNonDispalyValue } from "../commonComponents/EventHandler"
import { fetchAPIData } from "../../api/AuthService"
import { DOWNLOAD_LINK } from "../../constant/Constants"

export const CustomFppTransport = (props) => {
   let items = []
   items = props.transportUnitHead
   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState(false)
   const [errorMeg, setErrorMsg] = useState(false)

   const table_Contents = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={loading} spinner text="Downloading....">
            <Table borderless size="sm" responsive>
               <TransportUnitHead1 data={items.transportUnit} />
            </Table>
            <Table borderless size="sm" responsive>
               <TransportUnitHead2 data={items.transportUnit1} />
            </Table>
            </LoadingOverlay>
         </React.Fragment>
      )
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }

   const HeaderRow1 = (props) => {
      let topKeys = getKeys(props.data[0])
      return topKeys.map((key) => {
         if (setNonDispalyValue(key)) {
            return null
         } else if (key.toLowerCase().trim() === "dimensions - od with bulge") {
            return (
               <th key={key} colSpan="3">
                  <span>{key}</span>
               </th>
            )
         }else if (key.toLowerCase() === "zcsurl") {
            return <th rowSpan={props.span} key={key}></th>
         }
         else {
            return (
               <th rowSpan={props.span} key={key}>
                  <span>{key}</span>
               </th>
            )
         }
      })
   }

   const HeaderRow2 = (props) => {
      let bottomKeys = getKeys(props.data)
      return bottomKeys.map((key) => {
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const RowData = (props) => {
      let items = props.data
      let keys = getKeys(props.data[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            key.toLowerCase() === "name"
         ) {
            let name = props.data[key]
            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (
            props.data[key] != null &&
            typeof props.data[key] === "object" &&
            key.toLowerCase().trim() === "dimensions - od with bulge"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return colKeys.map((row, index) => {
               return <td key={index}>{props.data[key][row]}</td>
            })
         } else if (
            props.data[key] != null &&
            typeof props.data[key] === "object" &&
            key.toLowerCase().trim() === "stacking pattern gcas code"
         ) {
            let colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         }else if (key.toLowerCase() === "zcsurl") {
            let isFileAttached = props.data["isFileAttached"]
               ? props.data["isFileAttached"]
               : ""
            //check for access
            let isAccess = true
            let id = props.data["id"] ? props.data["id"] : props.data["Stacking Pattern GCAS Code"].id
            if (
               (id &&
                  id.toLowerCase().trim() === "no access") ||
               props.data["link"] === "PQR" ||
               isFileAttached === "false"
            ) {
               isAccess = false
            }

            return (
               <td key={index}>
                  {isAccess && id ? (
                     <button onClick={() => downloadFile(id.trim())}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  ) : (
                     <button disabled>
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  )}
               </td>
            )
         } else if (setNonDispalyValue(key)) {
            return null
         }       
         else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const TransportUnitHead1 = (props) => {
      let data = []
      data = props.data
      return (
         <React.Fragment>
            {data && data.length > 0 && (
               <React.Fragment>
                  <thead>
                     <tr>
                        <HeaderRow1 data={data} span={2} />
                     </tr>
                     <tr>
                        <HeaderRow2 data={data[0]["Dimensions - OD with Bulge"]} />
                     </tr>
                  </thead>
                  <tbody>
                     <RowData data={data} />
                  </tbody>
               </React.Fragment>
            )}
         </React.Fragment>
      )
   }

   const TransportUnitHead2 = (props) => {
      let data = []
      data = props.data
      return (
         <React.Fragment>
            {data && data.length > 0 && (
               <React.Fragment>
                  <thead>
                     <tr>
                        <HeaderRow1 data={data} span={0} />
                     </tr>
                  </thead>
                  <tbody>
                     <RowData data={data} />
                  </tbody>
               </React.Fragment>
            )}
         </React.Fragment>
      )
   }

   const downloadFile = (object_ID) => {
      var fileName = "download"
      setLoading(true)

      fetchAPIData(`${DOWNLOAD_LINK}${object_ID}`, { responseType: "blob" })
         .then(function (response) {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               fileName = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg("No file available to download.Please contact SpecReaderExt.im@pg.com for help.")
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else {
               setShow(true)
               setErrorMsg("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
            }
            setLoading(false)
         })
   }

   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   if (show) {
      return (
         <Alert
            style={{ textAlign: "center", marginLeft: "8rem", marginRight: "8rem" }}
            variant="info"
            onClose={() => setShow(false)}
            dismissible
         >
            <p>{errorMeg}</p>
         </Alert>
      )
   }

   return <div>{items && table_Contents()}</div>
}
export default CustomFppTransport
