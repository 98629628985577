import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row, ListGroupItem } from "react-bootstrap"
import { isLoggedIn, notAuthorized } from "../../../api/AuthService"
import { Table, ListGroup } from "react-bootstrap"
import textValidationCertRoll from "./textValidationCertRoll"
import ReportSubmitCertRollUp from "./ReportSubmitCertRollUp"

const certClaims = new Set()

function CertificationRollUp(props) {
   let textOnly = ""

   const [data, setData] = useState({
      reportName: props.reportName,
      latestReleseInput: true,
      partName: "",
      reportCustomName: "",
      template: "",
      includeChecked: false,
      changeAction:"",
      certificationClaims: [],
      certificationClaim: [],
     
   })
  
   

   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")
   const [certClaimPicklist, setcerClaimPicklist] = useState([])
   const [selected, setSelected] = useState([])
   const [deSelect, setDeSelect] = useState([])
   const [selectedCertificationClaims, setSelectedCertificationClaims] = useState([])
   React.useEffect(() => {

      setData((prevData) => ({
   
         ...prevData,
   
         certificationClaim: selectedCertificationClaims,
   
      }))
   
   }, [selectedCertificationClaims])



   const handleIncludeChange = (e) => {
      setData((data) => ({
         ...data,
         includeChecked: !data.includeChecked,
      }))
   }
   const handleSelect = (e, key) => {
      e.preventDefault()
      const selectedValue = e.target.innerText
      setSelected((prevElements) =>
         prevElements.includes(selectedValue)
            ? prevElements.filter((el) => el !== selectedValue)
            : [...prevElements, selectedValue]
      )
   }
   const handleDeSelect = (e, key) => {
      e.preventDefault()

      const deSelectedValue = e.target.innerText
      setDeSelect((prevElements) =>
         prevElements.includes(deSelectedValue)
            ? prevElements.filter((el) => el !== deSelectedValue)
            : [...prevElements, deSelectedValue]
      )
   }

   const business_content = certClaimPicklist.map((key, index) => (
      <ListGroupItem
         onClick={(event) => handleSelect(event, key)}
         key={index}
         className={`list-item ${selected.includes(key) ? "selected" : ""}`}
      >
         {key}
      </ListGroupItem>
   ))

   const selected_content = selectedCertificationClaims.map((item, index) => (
      <ListGroupItem
         onClick={(event) => handleDeSelect(event, item)}
         key={index}
         className={`list-item ${deSelect.includes(item) ? "selected" : ""}`}
      >
         {item}
      </ListGroupItem>
   ))

   const handleSelected = (e) => {
      if (e.target.innerText === ">") {
         if (selected !== "") {
            certClaims.add(selected)
            const cert_claims = [...new Set(selected)]
            setcerClaimPicklist((prevElements) =>
               prevElements.filter((el) => !cert_claims.includes(el))
            )
            setSelectedCertificationClaims((prevElements) =>
               [...prevElements, ...cert_claims].sort()
            )
            setSelected([])
         }
      } else {
         const de_cert_claims = [...new Set(deSelect)]
         setSelectedCertificationClaims((oldElements) =>
            oldElements.filter((el) => !de_cert_claims.includes(el))
         )
         setcerClaimPicklist((oldElements) => [...oldElements, ...de_cert_claims].sort())

         data.certificationClaim = selectedCertificationClaims

         setDeSelect([])
      }
   }

   const handleValidation = (code, msg) => {
      if (code === 999) {
         setSuccess(false)
         setMessage("Please enter Part Names to request a report.")
         setBlinkSearch("highlightFields")
      } else if (code === 200) {
         setSuccess(true)
         setMessage(
            "Your Report will be processed in the background and will complete shortly. Please refresh the screen after few minutes"
         )
      } else if (code === 996) {
         setSuccess(false)
         setMessage("Please enter input in valid format")
      } else if (code === 998) {
         setSuccess(false)
         setMessage("Please select atleast one option from Certification Picklist")
      } else if (code === 422 || code === 429 || code === 417 || code === 501) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 401) {
         notAuthorized(props.history)
      } else if (code === 423) {
         setSuccess(false)
         setMessage(
            "The maximum number of In process report requests (5) has been reached. Please submit another report later."
         )
      } else {
         setSuccess(false)
         setMessage("Report submission failed. Please contact SpecReaderExt.im@pg.com for help.")
      }
   }

   const clearMsg = () => {
      setMessage("")
   }

   const handleClearItems = () => {
      document.getElementById("view").value = "0"
      props.clearItem()
   }

   const handleInputChange = (e) => {
      e.preventDefault()
      setBlinkSearch("reportOptions")
      setMessage("")
      const textVal = textValidationCertRoll(e.target.value, e.target.name)
      if (textVal.props.children || e.target.value === "") {
         const { name, value } = e.target
         setData((data) => ({
            ...data,
            [name]: value,
         }))
      }
   }

  

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/login")
      } else {
         
         setData((data) => ({
            ...data,
            partName: props.templateData.partName ? props.templateData.partName : "",
            reportCustomName: props.templateData.reportCustomName
               ? props.templateData.reportCustomName
               : "",

              
               
            
            includeChecked: props.templateData.includeHyperlink
               ? props.templateData.includeHyperlink
               : false,
         }))
         setcerClaimPicklist(props.certificationClaims);
         if(props.templateCertClaim){
            setSelectedCertificationClaims(props.templateCertClaim)
            
            }
      }
   }, [props.templateData,props.certificationClaims,props.templateCertClaim])

   return (
      <React.Fragment>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
            Latest Release Part Only
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check type="checkbox" checked={true} disabled />
            </Col>
         </Form.Group>

         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Include Hyperlink
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  value={data.includeChecked}
                  checked={data.includeChecked}
                  onChange={handleIncludeChange}
               />
            </Col>
         </Form.Group>

         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="11" md="11" className="reportLabels">
               Please enter Part Name and Revision (use Name.Revision format). Multiple
               Parts may be separated with a comma or new line.
            </Form.Label>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Part and Revision
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="partName"
                  value={data.partName}
                  rows={3}
                  onChange={handleInputChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>

         
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label
               column
               sm="5"
               md="5"
               className="reportLabels"
               id="impInput"
               style={{ marginTop: "100px" }}
            >
               Certification Claim(s)
            </Form.Label>
            <Col sm="6" md="6">
               <Row>
                  <Col className="ColGrow">
                     <Form.Label style={{ marginLeft: "10px" }}>
                        Certification PickList
                     </Form.Label>
                     <div style={{ border: "1px solid black" }}>
                        <ListGroup className="BusinessList">{business_content}</ListGroup>
                     </div>
                  </Col>
                  <Col className="ColGrow">
                     <Button onClick={handleSelected}>{">"}</Button>
                     <Button onClick={handleSelected}>{"<"}</Button>
                  </Col>
                  <Col className="ColGrow">
                     <Form.Label style={{ marginLeft: "10px" }}>
                        Selected Certification(s)
                     </Form.Label>
                     <div style={{ border: "1px solid black" }}>
                        <ListGroup className="BusinessList">{selected_content}</ListGroup>
                     </div>
                  </Col>
               </Row>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Report Custom Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="reportCustomName"
                  value={data.reportCustomName}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>

         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Save as Template (New Template will be created)
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="template"
                  value={data.template}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>

         <Row>
            <Col md="11" sm="11" align="center">
               <p
                  style={{ marginTop: "4px", fontWeight: "bold" }}
                  className={success ? "successMsg" : "alertMsg"}
               >
                  {message}
               </p>
            </Col>
         </Row>
         <br />
         <ReportSubmitCertRollUp
            data={data}
            handleClearItems={handleClearItems}
            handler={handleValidation}
            clearMsg={clearMsg}
         />
         <br />
      </React.Fragment>
   )
}
export default CertificationRollUp
