import { Button, Col, Container, Row, Table, Form } from "react-bootstrap"
import React, { useRef, useEffect, useMemo, useState } from "react"
import Pagination from "react-pagination-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { DSM_DELETE } from "../../../constant/Constants"
import { DSMDelete } from "../../../api/AuthService"

const TemplateResults = ({ results, error, refreshHandler, load }, props) => {
   const history = useHistory()

   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (sortConfig.key === "Created Date") {
                  if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }
      return { items: sortedItems, requestSort, sortConfig }
   }

   const [resultsPerPage, setResultsPerPage] = useState(30)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const { items, requestSort, sortConfig } = useSortableData(results)
   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
   const[isAlertvisible,setIsAlertVisible]=React.useState(false);
   const [activePage, setActivePage] = useState(1)

   const selectedcurrentResult = useRef([])

   const handleSelectAllChange = () => {
      const selectAllStatus =
         selectedcurrentResult.current.length !== results.length

      if (selectAllStatus) {
         selectedcurrentResult.current = results.map((employee) => employee.id)
      } else {
         selectedcurrentResult.current = []
      }

      setForceUpdate((prevState) => !prevState)
   }

   const handleCheckboxChange = (userId) => {
      const index = selectedcurrentResult.current.indexOf(userId)

      if (index === -1) {
         selectedcurrentResult.current.push(userId)
      } else {
         selectedcurrentResult.current.splice(index, 1)
      }

      setForceUpdate((prevState) => !prevState)
   }

   const selectAll = selectedcurrentResult.current.length === results.length

   const isSendButtonDisabled = selectedcurrentResult.current.length === 0

   const [forceUpdate, setForceUpdate] = useState(false)
   let deleteForm;
   function show(){
      setIsAlertVisible(true);
      setTimeout(()=>{
         setIsAlertVisible(false);
      },4000)
   }

   const sendCurrentResultIDs = () => {
      deleteForm = window.confirm("Do you want to delete the selected Template(s)?")

      if (deleteForm) {
         const selectedIds = selectedcurrentResult.current

         DSMDelete(`${DSM_DELETE}`, { objectId: selectedIds })
            .then((response) => {})
            .catch((error) => {
               console.error("API errr", error)
            })
         show()
      }
   }

  

  

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }

   const info_content = currentResult.map((key, index) => (
      <tr key={key.id}>
         <td align="center" width="5%">
            {" "}
            <Form.Check
               type={props.type !== "organization" ? "checkbox" : "radio"}
               onChange={() => handleCheckboxChange(key.id)}
               checked={selectedcurrentResult.current.includes(key.id)}
            />
         </td>
         <td align="left" width="30%">
            {key["Report Type"]}
         </td>
         <td align="left" width="30%">
            <Link to={{ pathname: "/GenReport", state: key }}>
               {key["Template Name"]}
            </Link>
         </td>
         <td align="left" width="20%">
            {key["Created Date"]}
         </td>
      </tr>
   ))

   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }
   const handleRefresh=()=>{
      refreshHandler()
      window.location.reload();
   }

   return (
      <React.Fragment>
         <React.Fragment>
            <Container fluid className="searchDataContainer">
               <Row>
                  <Col
                     md="12"
                     sm="12"
                     align="center"
                     className="loginTextHeader"
                     style={{ marginTop: "10px" }}
                  >
                     Templates
                  </Col>
               </Row>
               {isAlertvisible && <Row>
            <Col md="11" sm="11" align="center">
               <p  id="msg"className="successMsg" style={{ marginTop: "4px", fontWeight: "bold" }} >
               Your Template(s) deletion request will be processed in the background and will complete shortly. Please refresh the screen after few minutes
               </p>
            </Col>
         </Row>} 
               <Row className="mb-3">
                  <Col md={7} sm={7} align="left">
                     <Button
                        name="Accept"
                        className="vendorOptions"
                        onClick={() => history.push("/GenReport")}
                        variant="primary"
                        style={{ border: "none" }}
                     >
                        Create Template
                     </Button>

                     <Button
                        variant="primary"
                        onClick={sendCurrentResultIDs}
                        style={{ border: "none", marginLeft: "5px" }}
                        disabled={isSendButtonDisabled}
                        className={isSendButtonDisabled ? "disabled" : ""}
                     >
                        Delete
                     </Button>

                     <Button
                        name="Accept"
                        className="vendorOptions"
                        onClick={handleRefresh}
                        variant="primary"
                        style={{ border: "none", marginLeft: "5px" }}
                     >
                        Refresh
                     </Button>

                     <Button
                        name="Accept"
                        className="vendorOptions"
                        onClick={() => history.goBack()}
                        variant="primary"
                        style={{ border: "none", marginLeft: "5px" }}
                     >
                        Cancel
                     </Button>
                  </Col>
                  <Col md={5} sm={5} align="right">
                     <div style={{ width: "40%", verticalAlign: "middle" }} id="paginTab">
                        {error === 0 && (
                           <Pagination
                              activePage={activePage}
                              itemsCountPerPage={resultsPerPage}
                              totalItemsCount={results.length}
                              pageRangeDisplayed={6}
                              onChange={handlePageChange}
                           />
                        )}
                     </div>
                  </Col>
               </Row>
               {load ? (
                  <h2 style={{ textAlign: "center", color: "#0173d4" }}>
                     <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                     />
                     Loading........
                  </h2>
               ) : (
                  <Table bordered>
                     <thead id="template_summ_tab">
                        <tr>
                           {error === 0 && props.type !== "organization" ? (
                              <th width="5%">
                                 <Form.Check
                                    type="checkbox"
                                    style={{ textAlign: "center" }}
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                 />
                              </th>
                           ) : (
                              <th width="3%" style={{ paddingRight: "10px" }}>
                                 <Form.Check
                                    type="checkbox"
                                    style={{ textAlign: "center" }}
                                    
                                 />
                              </th>
                           )}
                           <th width="30%">
                              Report Type&nbsp;
                              <i
                                 onClick={() => requestSort("Report Type")}
                                 className={getClassNamesFor("Report Type")}
                              ></i>
                           </th>
                           <th width="30%">
                              Template Name &nbsp;
                              <i
                                 onClick={() => requestSort("Template Name")}
                                 className={getClassNamesFor("Template Name")}
                              ></i>
                           </th>
                           <th width="20%">
                              Created Date &nbsp;
                              <i
                                 onClick={() => requestSort("Created Date")}
                                 className={getClassNamesFor("Created Date")}
                              ></i>
                           </th>
                        </tr>
                     </thead>
                     {error === 0 && <tbody>{info_content}</tbody>}
                     {error === 2 && (
                        <tbody>
                           <tr>
                              <td
                                 colSpan="4"
                                 style={{
                                    textAlign: "center",
                                    color: "#0173d4",
                                    fontSize: "18px",
                                 }}
                              >
                                 Template service issue. Please contact SpecReaderExt.im@pg.com for help.
                              </td>
                           </tr>
                        </tbody>
                     )}
                     {error === 1 && (
                        <tbody>
                           <tr>
                              <td
                                 colSpan="4"
                                 style={{
                                    textAlign: "center",
                                    color: "#0173d4",
                                    fontSize: "18px",
                                 }}
                              >
                                 No Records Found.
                              </td>
                           </tr>
                        </tbody>
                     )}
                  </Table>
               )}
            </Container>
         </React.Fragment>
      </React.Fragment>
   )
}

export default TemplateResults
