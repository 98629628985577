import React from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { pingLogin } from "../../api/AuthService"
import { FETCH_PING } from "../../constant/Constants"
import Logo from "../../resource/img/logo.png"

class Login extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         messsage: "",
         loading: true,
      }
   }
   componentDidMount() {
      if (this.props.location.search === "") {
         this.props.history.push("/")
      } else {
         this.setState({
            message: "",
            loading: true,
         })

         const queryStr = this.props.location.search
        
         pingLogin(`${FETCH_PING}${queryStr}`)
            .then(() => {
               this.setState({ loading: false })
               if(localStorage.getItem("gcas") && localStorage.getItem("gcas")!=="")
               {
                  this.props.history.push(`/${localStorage.getItem("gcas")}`)
               }
               else{
               this.props.history.push("/search")
               }
            })
            .catch((error) => {
               var errorMessage = ""
               if (error.response && error.response.status && error.response.status === 418) {
                  errorMessage = "418"
               }
               else{
                  errorMessage = "Login service issue. Please contact SpecReaderExt.im@pg.com for help."
               }
               this.setState({
                  message: errorMessage,
                  loading: false,
               })
            })
      }
   }
   render() {
      return (
         <React.Fragment>
            <Container fluid className="contentBackground sticky-top shadow">
               <Row>
                  <Col md="12">
                     <img src={Logo} alt="logo" style={{ width: "90px" }} />
                  </Col>
               </Row>
            </Container>
            <Container fluid>
               <Row>
                  <Col>
                     <Form.Row>
                        <Col md={{ span: 6, offset: 3 }} style={{marginTop:"10%"}}>
                           <br />
                           <p className="labels">
                              {this.state.loading ? (
                                 <h3 style={{ textAlign: "center", color: "white" }}>
                                    <i
                                       className="fa fa-spinner fa-spin"
                                       style={{ marginRight: "5px" }}
                                    />{" "}
                                    Granting access........
                                 </h3>
                              ) : (
                                 this.state.message === "418"?
                                 <span style={{fontSize:"16px"}}>
                                    You don’t have permission to access this application.<br/><br/>
                                    Please visit <a style ={{color:"white",textDecoration:"underline"}} href=" https://thejunction.pg.com/page/ppd/access-platform">The Junction</a> for access information. <br/><br/>
                                    For assistance with external user access please email dsmhelp.im@pg.com
                                 </span>
                                 : <span style={{ textAlign: "center", color: "white",fontSize:"16px" }}>{this.state.message}</span>
                              )}
                           </p>
                        </Col>
                     </Form.Row>
                  </Col>
               </Row>
            </Container>
         </React.Fragment>
      )
   }
}
export default Login