import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { fetchAPIData, notAuthorized } from "../../api/AuthService"
import { FETCH_SEARCH } from "../../constant/Constants"
import "../main.css"
import SearchResultNew from "./SearchResultNew"

const SearchResultDisp = (props) => {
   const [error, setError] = useState("Error in services")
   const [errorFlag, setErrorFlag] = useState(0)
   const [loading, setLoading] = useState(false)
   const [results, setResults] = useState([])
   const [completeData, setCompleteData] = useState([])
   const [totalRecords, setTotalRecords] = useState([0])

   const searchData = props.searchData
   const searchType = props.searchType ? props.searchType : "gcas"

   const GetSortOrder = (date) => {
      return function(a, b) {
         if (new Date(a[date]) > new Date(b[date])) {    
             return 1
         }   
         else if (new Date(a[date]) < new Date(b[date])) {    
            return -1;    
        }    
        return 0;
     }    
   }

   const handleClientSearch =(data)=>{
      setResults(data)
   }

   useEffect(() => {
      async function getSearchData() {
         try {
            // var searchCacheKey = ""

            // searchCacheKey = `${searchData}_${searchType}`

            setStateValues(setLoading, setError, setErrorFlag)

            /// var apiData;
            var searchParms = {
               searchText: atob(searchData),
               searchType: searchType,
            }

            // if (localStorage.getItem(searchCacheKey) && localStorage.getItem(searchCacheKey) !== "") {
            //     apiData = JSON.parse(localStorage.getItem(searchCacheKey))
            // }
            // else {
            // Load async data from an Fpp Api endpoint.
            // const response = await fetchAPIData(`${FETCH_SEARCH}/${searchData}`);
            const response = await fetchAPIData(`${FETCH_SEARCH}`, {
               params: searchParms,
            })
            // apiData = response.data
            //   localStorage.setItem(searchCacheKey, JSON.stringify(response.data))
            //  apiData = JSON.parse(localStorage.getItem(searchCacheKey))
            // }
            // Load async data from Api endpoint.
            //const response = await fetchAPIData(`${FETCH_SEARCH}/${searchData}`);
            var data = response.data.searchedRecords; 
            setResults(data)
            setCompleteData(data)
            if (
               response.data.searchedRecords &&
               response.data.searchedRecords.length > 0
            ) {
               setErrorFlag(1)

               var count = response.data.searchedRecords.length
               count = count && count > 999 ? "1000+" : count

               setTotalRecords(count)
               // setTotalRecords(response.data.totalRecords)
            } else {
               setErrorFlag(2)
            }
            setLoading(false)
         } catch (error) {
            if (error.response && error.response.data.status === 401) {
               notAuthorized(props.history)
               return
            }
            if (error.response && error.response.data.status === 403) {
               console.log("Error", ` No access to this object: ${error}`)
               props.history.push("/NotAccess")
               return
            }
            setError("Search service issue. Please contact SpecReaderExt.im@pg.com for help.")
            setErrorFlag(3)
            setLoading(false)
            console.log("Error", ` API request failed: ${error}`)
         }
      }
      getSearchData()
   }, [searchData, searchType, props.history])

   return (
      <React.Fragment>
         {loading && (
            <h2 style={{ textAlign: "center", color: "white" }}>
               <i className="fa fa-spinner fa-spin" style={{ marginLeft: "-4rem" }} />{" "}
               Loading........
            </h2>
         )}
         {errorFlag === 2 && (
            <Row className="labels">
               <Col md={{ span: 4, offset: 4 }}>
                  &nbsp; No record(s) found matching the search criteria.
               </Col>
            </Row>
         )}

         {errorFlag === 3 && (
            <Row className="labels">
               <Col md={{ span: 6, offset: 4 }}>{error}</Col>
            </Row>
         )}

         {errorFlag === 1 && (
            <Container fluid>
               <Row className="labels">
                  <Col>{totalRecords} Record(s) Found.</Col>
               </Row>
               <Row>
                  <Col md="12" sm="12" style={{ marginTop: "0px" }}>
                        <SearchResultNew results={results} completeData={completeData} handleClientSearch={handleClientSearch}/>
                  </Col>
               </Row>
            </Container>
         )}
      </React.Fragment>
   )
}
export default SearchResultDisp

function setStateValues(setLoading, setError, setErrorFlag) {
   setLoading(true)
   setError("")
   // setCurrentPage(1);
   setErrorFlag(0)
}
