import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Button } from "react-bootstrap"
import { fetchAPIData } from "../../api/AuthService"
import { DOWNLOAD_LINK } from "../../constant/Constants"
import { expandCollapseAll } from "../commonComponents/EventHandler"

const PdfDownloadComponent = ({ objectID }) => {
   const [genDocLoading, setGedDocLoading] = useState(false)

   //Download GenDoc File
   const downloadGenDocFile = () => {
      var fileName = "download"

      setGedDocLoading(true)

      fetchAPIData(`${DOWNLOAD_LINK}${objectID}`, { responseType: "blob" })
         .then(function (response) {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               fileName = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setGedDocLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               alert("No file is avilable to download.Please contact SpecReaderExt.im@pg.com for help.")
            } else if (error.response && error.response.status === 422) {
               // setShow(true)
               alert(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else {
               alert("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
               console.log("FCS Error Response:-", error)
            }
            setGedDocLoading(false)
         })
   }

   return (
      <div>
         {objectID && (
            <div>
               <Button
                  style={{ textAlign: "center" }}
                  className="expandCollapseButton"
                  onClick={downloadGenDocFile}
               >
                  {genDocLoading ? (
                     <i
                        className="fa fa-spinner fa-spin"
                        style={{ marginRight: "5px" }}
                     />
                  ) : (
                     <span>GenDoc &#8595;</span>
                  )}
               </Button>{" "}
               &nbsp;
               <Button
                  id="btnExpandAll"
                  className="expandCollapseButton shadow"
                  onClick={expandCollapseAll}
               >
                  Collapse All
               </Button>
            </div>
         )}
      </div>
   )
}
export default PdfDownloadComponent
