import React, { useCallback, useEffect, useRef, useState } from "react"
import Modal from "react-bootstrap/Modal"
import { fetchAPIData, isLoggedIn, PutData } from "../../api/AuthService"
import {
   FETCH_VENDOR_PREFERENCE,
   UPDATE_VENDOR_PREFERENCE,
   SELECTED_VENDOR_PREFERENCE,
} from "../../constant/Constants"
import { Col, Form, Row, Button, ListGroup, ListGroupItem } from "react-bootstrap"
import { useLocation, useHistory } from "react-router-dom"

function VendorPreference(props) {
   const [vendors, setVendors] = useState([])
   const [selected, setSelected] = useState([])
   const [deSelected, setDeSelected] = useState([])
   const [selectedData, setSelectedData] = useState([])
   const [searchedVendors, setSearchedVendors] = useState([])
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState()
   const [search, setSearch] = useState("")
   const [submitLoading, setSubmitLoading] = useState(false)
   const [inactiveVendors, setInactiveVendors] = useState("")
   const dataRef = useRef(vendors)
   const User = localStorage.getItem("UserId")
   const location = useLocation()
   const history = useHistory()

   const fetchData = async () => {
      try {
         setLoading(true)
         const response = await fetchAPIData(`${FETCH_VENDOR_PREFERENCE}`)
         if (response.data && response.data.length !== 0) {
            setVendors(response.data.vendors)
            setError(0)
         } else {
            setError(1)
         }
         const response2 = await fetchAPIData(`${SELECTED_VENDOR_PREFERENCE}/${User}`)
         if (response2.data && response2.data.length !== 0) {
            setSelectedData(response2.data.vendors)
            if (response2.data.InactiveVendors != "") {
               setInactiveVendors(response2.data.InactiveVendors)
            }
            setLoading(false)
            setError(0)
         } else {
            setLoading(false)
         }
      } catch (error) {
         setLoading(false)
         setError(2)
      }
      console.log("error", error)
   }

   function handleClose() {
      setVendors([])
      setSearchedVendors([])
      setSelectedData([])
      setDeSelected([])
      setSelected([])
      setError()
      props.handleVendorModal()
   }

   function handleSave() {
      const { pathname } = location
      const data = {
         vendors: selectedData,
      }
      setSubmitLoading(true)
      PutData(`${UPDATE_VENDOR_PREFERENCE}`, data)
         .then((response) => {
            setSubmitLoading(false)
            handleClose()
         })
         .catch((error) => {
            if (error.response && error.response.status) {
               console.log(error.response.status, error.response.data.message)
            } else {
               console.log(1000, error)
            }
            setSubmitLoading(false)
         })
      if (pathname == "/vendorInfo") {
         window.location.reload()
      }
   }

   const debounce = (func, delay = 300) => {
      let timeout
      return (...args) => {
         clearTimeout(timeout)
         timeout = setTimeout(() => {
            func.apply(null, args)
         }, delay)
      }
   }

   const handleSearchChange = useCallback(
      debounce((search) => {
         const data = dataRef.current
         if (search !== "") {
            let escapedSearch=search.replace(/([|\\^$*+?.(){}[\]\-\_~&])/g,'\\$1')
            let regex = new RegExp(escapedSearch, "i")
            var matches = data.filter((vendor) => regex.test(vendor.name))
            setSearchedVendors(matches)
         } else {
            setSearchedVendors([])
         }
      }),
      []
   )

   const handleSelect = (e, key) => {
      e.preventDefault()
      const value = key
      if (value) {
         if (selected.includes(value)) {
            setSelected(selected.filter((val) => val !== value))
         } else {
            setSelected([...selected, value])
         }
      }
   }

   const handleDeSelect = (e, key) => {
      e.preventDefault()
      const value = key
      if (value) {
         if (deSelected.includes(value)) {
            setDeSelected(deSelected.filter((val) => val != value))
         } else {
            setDeSelected([...deSelected, value])
         }
      }
   }

   const searchChanges = (e) => {
      setSearch(e.target.value)
   }

   const vendor_content = searchedVendors.map((key, index) => (
      <ListGroupItem
         onClick={(e) => handleSelect(e, key)}
         key={index + 1}
         className={selected.includes(key) ? "selected" : ""}
      >
         {key.name}
      </ListGroupItem>
   ))
   const selected_content = selectedData.map((key, index) => (
      <ListGroupItem
         onClick={(e) => handleDeSelect(e, key)}
         key={index + 1}
         className={deSelected.includes(key) ? "selected" : ""}
      >
         {key.name}
      </ListGroupItem>
   ))

   const handleSelected = (e) => {
      if (e.target.innerText === ">") {
         let data = selectedData.concat(
            selected.filter((val) => !selectedData.some((obj) => obj.name === val.name && obj.Id === val.Id))
         )
         setSelectedData(data)
         setSelected([])
      } else {
         setSelectedData(selectedData.filter((value) => !deSelected.includes(value)))
         setDeSelected([])
      }
   }

   useEffect(() => {
      dataRef.current = vendors
   }, [vendors])

   useEffect(() => {
      handleSearchChange(search)
   }, [search, handleSearchChange])

   useEffect(() => {
      if (!isLoggedIn()) {
         history.push("/login")
      } else if (props.show) {
         fetchData()
      }
   }, [props.show])

   return (
      <React.Fragment>
         <Modal
            show={props.show}
            onHide={props.handleVendorModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="my-modal2"
            style={{ overflowY: "hidden" }}
            backdrop="static"
            keyboard={false}
         >
            <Modal.Header>
               <Modal.Title>Vendor preference</Modal.Title>
               <button type="button" className="close" onClick={handleClose}>
                  &times;
               </button>
            </Modal.Header>

            <Modal.Body>
               <Form>
                  {loading ? (
                     <h2 style={{ textAlign: "center", color: "#0173d4" }}>
                        <i
                           className="fa fa-spinner fa-spin"
                           style={{ marginRight: "5px" }}
                        />
                        Loading........
                     </h2>
                  ) : (
                     <React.Fragment>
                        {error === 0 && (
                           <>
                              {inactiveVendors != "" && (
                                 <div
                                    style={{
                                       color: "#A00505",
                                       fontSize: "14px",
                                       textAlign: "center",
                                       fontWeight: "600",
                                    }}
                                 >
                                    {inactiveVendors}
                                 </div>
                              )}
                              <Row>
                                 <Col md="3" sm="4" style={{ alignSelf: "center" }}>
                                    <Form.Label className="loginTextHeader">
                                       Sites/Vendors
                                    </Form.Label>
                                 </Col>
                                 <Col md="12" sm="12">
                                    <Row>
                                       <Form.Control
                                          type="input"
                                          style={{
                                             marginLeft: "15px",
                                             marginBottom: "10px",
                                             width: "28%",
                                          }}
                                          placeholder="Search Sites/Vendors"
                                          onChange={searchChanges}
                                       />
                                    </Row>
                                 </Col>
                                 <Col xl={12}>
                                    <Row>
                                       <Col className="ColGrow" xl={5}>
                                          <div style={{ border: "1px solid black" }}>
                                             <ListGroup className="BusinessListpreferance">
                                                {vendor_content}
                                             </ListGroup>
                                          </div>
                                       </Col>
                                       <Col
                                          xl={1}
                                          style={{
                                             display: "flex",
                                             flexDirection: "column",
                                          }}
                                       >
                                          <Button
                                             className="ColGrow1"
                                             onClick={handleSelected}
                                          >
                                             {">"}
                                          </Button>
                                          <Button
                                             className="ColGrow2"
                                             onClick={handleSelected}
                                          >
                                             {"<"}
                                          </Button>
                                       </Col>
                                       <Col className="ColGrow" xl={5}>
                                          <div style={{ border: "1px solid black" }}>
                                             <ListGroup className="BusinessListpreferance">
                                                {selected_content}
                                             </ListGroup>
                                          </div>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                           </>
                        )}
                        {error === 1 && (
                           <Form.Group as={Row}>
                              <Form.Label
                                 column
                                 md="12"
                                 className="reportLabels1 mt-20"
                                 align="center"
                              >
                                 No Results Found
                              </Form.Label>
                           </Form.Group>
                        )}
                        {error === 2 && (
                           <Form.Group as={Row}>
                              <Form.Label
                                 column
                                 md="12"
                                 className="reportLabels1 mt-20"
                                 align="center"
                                 style={{ color: "#a00505" }}
                              >
                                 Search service issue. Please contact SpecReaderExt.im@pg.com for help.
                              </Form.Label>
                           </Form.Group>
                        )}
                     </React.Fragment>
                  )}
               </Form>
            </Modal.Body>

            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Close
               </Button>
               {error === 0 && (
                  <Button variant="primary" onClick={() => handleSave(selectedData)}>
                     {submitLoading ? (
                        <i
                           className="fa fa-spinner fa-spin"
                           style={{ marginRight: "5px" }}
                        />
                     ) : (
                        <span>Save</span>
                     )}
                  </Button>
               )}
            </Modal.Footer>
         </Modal>
      </React.Fragment>
   )
}

export default VendorPreference
