import { saveAs } from "file-saver"
import React, { useRef, useEffect, useMemo, useState } from "react"
import { Button, Col, Dropdown, DropdownButton, Row, Table, Form } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import Pagination from "react-pagination-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { GenDocFileDownload, isLoggedIn, notAuthorized } from "../../api/AuthService"
import { POA_REFDOC_DOWNLOAD } from "../../constant/Constants"
import { DSMDelete } from "../../api/AuthService"
import { DSM_DELETE } from "../../constant/Constants"

let newSet = []
const ReportResults = ({ results, error, refreshHandler, load, type }, props) => {
   const history = useHistory()
   const [data, setData] = useState([])
   const [loading, setLoading] = useState(false)
   const [text, setText] = useState("")
   const [choices, setChoices] = useState([])
   const [mainCheckbox, setMainCheckbox] = useState([])
   const useSortableData = (items, config = null) => {
      const [sortConfig, setSortConfig] = useState(config)
      const sortedItems = useMemo(() => {
         let sortableItems = [...items]
         if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
               if (
                  sortConfig.key === "Check In Date" ||
                  sortConfig.key === "Date Requested"
               ) {
                  if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key])) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               } else {
                  if (a[sortConfig.key] < b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? -1 : 1
                  }
                  if (a[sortConfig.key] > b[sortConfig.key]) {
                     return sortConfig.direction === "ascending" ? 1 : -1
                  }
                  return 0
               }
            })
         }
         return sortableItems
      }, [items, sortConfig])

      const requestSort = (key) => {
         let direction = "ascending"
         if (
            sortConfig &&
            sortConfig.key === key &&
            sortConfig.direction === "ascending"
         ) {
            direction = "descending"
         }
         setSortConfig({ key, direction })
      }

      return { items: sortedItems, requestSort, sortConfig }
   }

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return "fa fa-sort"
      } else if (sortConfig.key === name) {
         if (sortConfig.direction === "ascending") {
            return "fa fa-caret-up"
         } else {
            return "fa fa-caret-down"
         }
      } else {
         return "fa fa-sort"
      }
   }

   const [resultsPerPage, setResultsPerPage] = useState(30)
   const [currentPage, setCurrentPage] = useState(1)
   const indexOfLastResult = currentPage * resultsPerPage
   const indexOfFirstResult = indexOfLastResult - resultsPerPage
   const { items, requestSort, sortConfig } = useSortableData(data)
   const currentResult = items.slice(indexOfFirstResult, indexOfLastResult)
   const[isAlertvisible,setIsAlertVisible]=React.useState(false);
   newSet = currentResult

   const [newCurrentResult, setNewCurrentResult] = useState(
      items.slice(indexOfFirstResult, indexOfLastResult)
   )

   const [activePage, setActivePage] = useState(1)

   const selectedcurrentResult = useRef([])

   const handleSelectAllChange = () => {
      const selectAllStatus =
         selectedcurrentResult.current.length !== data.length
      if (selectAllStatus) {
         selectedcurrentResult.current = data.map((employee) => employee.id)
      } else {
         selectedcurrentResult.current = []
      }
      setForceUpdate((prevState) => !prevState)
   }

   const handleCheckboxChange = (userId) => {
      const index = selectedcurrentResult.current.indexOf(userId)
      if (index === -1) {
         selectedcurrentResult.current.push(userId)
      } else {
         selectedcurrentResult.current.splice(index, 1)
      }
      setForceUpdate((prevState) => !prevState)
   }

   const selectAll = selectedcurrentResult.current.length === data.length

   const [forceUpdate, setForceUpdate] = useState(false)
   let deleteForm;
   function show(){
      setIsAlertVisible(true);
      setTimeout(()=>{
         setIsAlertVisible(false);
      },4000)
   }
   const sendCurrentResultIDs = () => {
      deleteForm = window.confirm("Do you want to delete the selected Report(s)?")

      if (deleteForm) {
         const selectedIds = selectedcurrentResult.current

         DSMDelete(`${DSM_DELETE}`, { objectId: selectedIds })
            .then((response) => {})
            .catch((error) => {
               console.error("API errr", error)
            })
            show()
      }
   }

   

   useEffect(() => {
      if (!isLoggedIn()) {
         history.push("/login")
      } else {
         setData(results)
      }
   }, [results])

   const isSendButtonDisabled = selectedcurrentResult.current.length === 0

   const downloadGenDocFiles = (fileName, filePath) => {
      var downloadFilename = "download"
      setText("Downloading.....")
      setLoading(true)
      GenDocFileDownload(
         `${POA_REFDOC_DOWNLOAD}`,
         fileName,
         filePath,
         "",
         {},
         {},
         "",
         "report"
      )
         .then((response) => {
            if (
               response.headers["filename"] != null &&
               response.headers["filename"] !== ""
            ) {
               downloadFilename = response.headers["filename"]
            }
            const fileContents = new Blob([response.data])

            saveAs(fileContents, downloadFilename)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               alert("The DSM Report is in progress and not yet available for download.")
            } else if (error.response && error.response.status === 403) {
               alert("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
            } else if (error.response && error.response.status === 422) {
               alert(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else if (error.response && error.response.status === 401) {
               notAuthorized(history)
            } else {
               console.log("FCS Error Response:-", error)
            }
            setLoading(false)
         })
   }

   const info_content = currentResult.map((key, index) => (
      <tr key={key.id}>
         <td align="center" width="5%">
            {" "}
            <Form.Check
               type={props.type !== "organization" ? "checkbox" : "radio"}
              
               onChange={() => handleCheckboxChange(key.id)}
               checked={selectedcurrentResult.current.includes(key.id)}
               
            />
         </td>
         <td width="15%">
            {key.id != null ? (
               <Link
                  to={(location) => ({
                     ...location,
                     pathname: `/ReportDetail/${key.id}`,
                  })}
               >
                  <span textalign="left">{key.Name}</span>
               </Link>
            ) : (
               <span>{key.Name}</span>
            )}
         </td>
         <td align="left" width="20%">
            {key && key["File Name"] && key["filePath"] ? (
               <Button
                  variant="link"
                  onClick={() =>
                     downloadGenDocFiles(key["File Name"].trim(), key["filePath"].trim())
                  }
               >
                  {key["File Name"]}
               </Button>
            ) : (
               <Button variant="link" disabled>
                  {key["File Name"]}
               </Button>
            )}
         </td>
         <td align="left" width="10%">
            {key.State}
         </td>
         <td align="left" width="15%">
            {key["Date Requested"]}
         </td>
         <td align="left" width="15%">
            {key["Check In Date"]}
         </td>
         <td align="center" width="5%">
            {key && key["File Name"] && key["filePath"] ? (
               <Button
                  variant="link"
                  onClick={() =>
                     downloadGenDocFiles(key["File Name"].trim(), key["filePath"].trim())
                  }
               >
                  <i className="fa fa-download fa-2x" aria-hidden="true"></i>
               </Button>
            ) : (
               <Button variant="link" disabled>
                  <i className="fa fa-download fa-2x" aria-hidden="true"></i>
               </Button>
            )}
         </td>
      </tr>
   ))

   function handlePageChange(pageNumber) {
      setCurrentPage(pageNumber)
      setActivePage(pageNumber)
   }
   const handleRefresh=()=>{
      refreshHandler()
      window.location.reload();
   }

   return (
      <React.Fragment>
         <React.Fragment>
         {isAlertvisible && <Row>
            <Col md="11" sm="11" align="center">
               <p  id="msg"className="successMsg" style={{ marginTop: "4px", fontWeight: "bold" }} >
               Your Report(s) deletion request will be processed in the background and will complete shortly. Please refresh the screen after few minutes
               </p>
            </Col>
         </Row>} 
            <Row
               style={{ marginBottom: "10px", paddingLeft: "15px", paddingRight: "15px" }}
            >
               <Col className="action" align="right">
                  <DropdownButton id="dropdown-basic-button" title="Actions">
                     <Dropdown.Item
                        className="dropdownBtnItem"
                        onClick={() => history.push("/GenReport")}
                     >
                        Report Request
                     </Dropdown.Item>
                     <Dropdown.Item className="dropdownBtnItem" onClick={handleRefresh}>
                        Refresh
                     </Dropdown.Item>
                  </DropdownButton>
                  {/* Dropdown Buttons end */}
               </Col>
               <Col className="template">
                  <Button variant="primary" onClick={() => history.push("/Templates")}>
                     Templates
                  </Button>
               </Col>
               <Col className="delete">
                  <Button
                     variant="primary"
                     onClick={sendCurrentResultIDs}
                     disabled={isSendButtonDisabled}
                     className={isSendButtonDisabled ? "disabled" : ""}
                     // onClick={() => history.delete("/Templates")}
                  >
                     Delete
                  </Button>
               </Col>
               <Col className="pageinationSub" align="right">
                  <div style={{ width: "40%", verticalAlign: "middle" }} id="paginTab">
                     {error === 0 && (
                        <Pagination
                           activePage={activePage}
                           itemsCountPerPage={resultsPerPage}
                           totalItemsCount={data.length}
                           pageRangeDisplayed={6}
                           onChange={handlePageChange}
                        />
                     )}
                  </div>
               </Col>
            </Row>
            {load ? (
               <h2 style={{ textAlign: "center", color: "#0173d4" }}>
                  <i className="fa fa-spinner fa-spin" style={{ marginRight: "5px" }} />
                  Loading........
               </h2>
            ) : (
               <LoadingOverlay active={loading} spinner text={text}>
                  <div className="searchScrollbar">
                     <Table bordered>
                        <thead id="template_summ_tab">
                           <tr>
                              {error === 0 && props.type !== "organization" ? (
                                 <th width="10px">
                                    <Form.Check
                                       type="checkbox"
                                       style={{ textAlign: "center" }}
                                       onChange={handleSelectAllChange}
                                       
                                       checked={selectAll}
                                       
                                    />
                                 </th>
                              ) : (
                                 <th width="3%" style={{ paddingRight: "30px" }}>
                                    <Form.Check
                                    type="checkbox"
                                    style={{ textAlign: "center" }}
                                    
                                 />
                                 </th>
                              )}

                              <th
                                 width="15%"
                                 style={{ width: "35px", height: "", marginLeft: "20px" }}
                              >
                                 Name&nbsp;
                                 <i
                                    onClick={() => requestSort("Name")}
                                    className={getClassNamesFor("Name")}
                                 ></i>
                              </th>
                              <th width="20%">
                                 File Name &nbsp;
                                 <i
                                    onClick={() => requestSort("File Name")}
                                    className={getClassNamesFor("File Name")}
                                 ></i>
                              </th>
                              <th width="10%">
                                 State &nbsp;
                                 <i
                                    onClick={() => requestSort("State")}
                                    className={getClassNamesFor("State")}
                                 ></i>
                              </th>
                              <th width="15%">
                                 Date Requested &nbsp;
                                 <i
                                    onClick={() => requestSort("Date Requested")}
                                    className={getClassNamesFor("Date Requested")}
                                 ></i>
                              </th>
                              <th width="15%">
                                 Check In Date &nbsp;
                                 <i
                                    onClick={() => requestSort("Check In Date")}
                                    className={getClassNamesFor("Check In Date")}
                                 ></i>
                              </th>

                              <th width="5%">File Download &nbsp;</th>
                           </tr>
                        </thead>
                        {error === 1 && (
                           <tbody>
                              <tr>
                                 <td
                                    colSpan="7"
                                    style={{
                                       textAlign: "center",
                                       color: "#0173d4",
                                       fontSize: "18px",
                                    }}
                                 >
                                    No Records Found.
                                 </td>
                              </tr>
                           </tbody>
                        )}
                        {error === 2 && (
                           <tbody>
                              <tr>
                                 <td
                                    colSpan="7"
                                    style={{
                                       textAlign: "center",
                                       color: "#0173d4",
                                       fontSize: "18px",
                                    }}
                                 >
                                    Report service issue. Please contact SpecReaderExt.im@pg.com for help.
                                 </td>
                              </tr>
                           </tbody>
                        )}
                        {error === 0 && <tbody>{info_content}</tbody>}
                     </Table>
                  </div>
               </LoadingOverlay>
            )}
         </React.Fragment>
      </React.Fragment>
   )
}
export default ReportResults

