import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { fetchAPIData, isLoggedIn, notAuthorized } from "../../api/AuthService";
import { FETCH_SEARCH } from "../../constant/Constants";
import HeaderComponent from "../commonComponents/HeaderComponent";
import SearchResults from "../search/SearchResults";


function GSACRoute(props) {
    const [error, setError] = useState("Error in services")
    const [errorFlag, setErrorFlag] = useState(0)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [totalRecords, setTotalRecords] = useState([0])
    const gcas = props.history.location.pathname?props.history.location.pathname.split("/")[1]:""

    function setStateValues(setLoading, setError, setErrorFlag) {
        setLoading(true)
        setError("")
        setErrorFlag(0)
     }
     
    async function fetchData() {

        setStateValues(setLoading, setError, setErrorFlag)

        var searchParams = {
            searchText: props.match.params.objectID,
            searchType: "gcas"
        }
        //Get data by API call
        try {
            const response = await fetchAPIData(`${FETCH_SEARCH}`, {
                params: searchParams
            })
            if (response.data.searchedRecords && response.data.searchedRecords.length === 1) {
                const Data = response.data.searchedRecords[0]
                if(Data.link && Data.id && Data.id.toLowerCase().trim() !== "no access"){
                    let redirectUrl = `${process.env.REACT_APP_DOMAIN_NAME}/${Data.link}/${Data.id}`
                    window.location.href = redirectUrl
                }
                else{
                    props.history.push("/NotAccess")
                }
            }
            else if (response.data.searchedRecords && response.data.searchedRecords.length > 1) {
                setResults(response.data.searchedRecords)
                var count = response.data.searchedRecords.length
                count = count && count > 999 ? "1000+" : count
                setTotalRecords(count)
                setErrorFlag(3)
            }
            else{
                setErrorFlag(1)
            }
            setLoading(false)
        } catch (error) {
            if (error.response && error.response.data.status === 401) {
                notAuthorized(props.history)
                return
            }
            if (error.response && error.response.data.status === 403) {
                console.log("Error", ` No access to this object: ${error}`)
                props.history.push("/NotAccess")
                return
            }
            setError("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
            setErrorFlag(2)
            setLoading(false)
            console.log("Error", ` API request failed: ${error}`)
        }
    }

    useEffect(() => {
        if (!isLoggedIn("gcas", gcas)) {
            props.history.push("/")
        } else {
            if (props.match.params.objectID) {
                fetchData() //Fetch Data from API
            }
        }
    }, [props.history])

    return (
        <React.Fragment>
            <HeaderComponent />
            {loading && (
                <h2 style={{marginTop: "70px", textAlign: "center", color: "white" }}>
                    <i className="fa fa-spinner fa-spin" style={{ marginLeft: "-4rem" }} />{" "}
                    Loading........
                </h2>
            )}
            {errorFlag === 1 && (
                <Row className="labels" style={{marginTop: "70px"}}>
                    <Col md={{ span: 4, offset: 4 }}>
                        &nbsp; No record(s) found matching the search criteria.
                    </Col>
                </Row>
            )}
            {errorFlag === 2 && (
                <Row className="labels" style={{marginTop: "70px"}}>
                    <Col md={{ span: 6, offset: 4 }} sm={{ span: 8, offset: 2 }}>{error}</Col>
                </Row>
            )}
            {errorFlag === 3 && (
                <Container fluid style={{marginTop: "70px",marginBottom:"20px"}}>
                    <Row className="labels">
                        <Col>{totalRecords} Record(s) Found.</Col>
                    </Row>
                    <Row>
                        <Col md="12" style={{ marginTop: "0px" }}>
                            <Container fluid className="searchDataContainer">
                                <SearchResults results={results} loading={loading} />
                            </Container>
                        </Col>
                    </Row>
                </Container>
            )}
        </React.Fragment>
    )

}
export default GSACRoute