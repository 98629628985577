import React, { useEffect, useState, useRef } from "react"
import { Col, Form, Row, Table, ListGroup, ListGroupItem, Button } from "react-bootstrap"
import { isLoggedIn, notAuthorized } from "../../../api/AuthService"

import textValidation from "./textValidation"
import ReportSubmitCharMaster from "./ReportSubmitCharMaster"

const businessArea = new Set()

function CharMasterReport(props) {
   const [data, setData] = useState({
      reportName: props.reportName,
      search: props.search,
      // latestReleaseCMInput: false,
      releaseCMonly: true,
      buisness_Areas: [],
      reportCustomName: "",
      template: "",
      changeAction:"",
      partName: "",
      buisness_Area: [],
   })
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")
   const [bus_areaPicklist, setbus_areaPicklist] = useState([])
   const [selected, setSelected] = useState([])
   const [deSelect, setDeSelect] = useState([])
   const [selectedBusinessArea, setSelectedBusinessArea] =useState([])
   React.useEffect(() => {

      setData((prevData) => ({ ...prevData, buisness_Areas: selectedBusinessArea }))

   }, [selectedBusinessArea])
   const handlelatestReleaseCMInput = (e) => {
      setData((data) => ({
         ...data,

         // latestReleaseCMInput: !data.latestReleaseCMInput,
      }))
   }

   
   const handleRelease = (e) => {
      setData((data) => ({
         ...data,
         releaseCMonly: !data.releaseCMonly,
         // latestReleaseCMInput: false,
      }))
   }

   const handleLatestRelease = (e) => {
      setData((data) => ({
         ...data,
         releaseCMonly: false,
         // latestReleaseCMInput: !data.latestReleaseCMInput,
      }))
   }

   const handleSelect = (e, key) => {
      e.preventDefault()
      const selectedValue = e.target.innerText
      setSelected((prevElements) =>
         prevElements.includes(selectedValue)
            ? prevElements.filter((el) => el !== selectedValue)
            : [...prevElements, selectedValue]
      )
   }

   const handleDeSelect = (e, key) => {
      e.preventDefault()

      const deSelectedValue = e.target.innerText
      setDeSelect((prevElements) =>
         prevElements.includes(deSelectedValue)
            ? prevElements.filter((el) => el !== deSelectedValue)
            : [...prevElements, deSelectedValue]
      )
   }

   const business_content = bus_areaPicklist.map((key, index) => (
      <ListGroupItem
         onClick={(event) => handleSelect(event, key)}
         key={index}
         className={`list-item ${selected.includes(key) ? "selected" : ""}`}
      >
         {key}
      </ListGroupItem>
   ))

   const selected_content = selectedBusinessArea.map((item, index) => (
      <ListGroupItem
         onClick={(event) => handleDeSelect(event, item)}
         key={index}
         className={`list-item ${deSelect.includes(item) ? "selected" : ""}`}
      >
         {item}
      </ListGroupItem>
   ))

   const handleSelected = (e) => {
      if (e.target.innerText === ">") {
         if (selected !== "") {
            businessArea.add(selected)
            const bus_area = [...new Set(selected)]
            setbus_areaPicklist((prevElements) =>
               prevElements.filter((el) => !bus_area.includes(el))
            )
            setSelectedBusinessArea((prevElements) =>
               [...prevElements, ...bus_area].sort()
            )
            setSelected([])
         }
      } else {
         const de_bus_area = [...new Set(deSelect)]
         setSelectedBusinessArea((oldElements) =>
            oldElements.filter((el) => !de_bus_area.includes(el))
         )
         setbus_areaPicklist((oldElements) => [...oldElements, ...de_bus_area].sort())

         data.buisness_Areas = selectedBusinessArea

         setDeSelect([])
      }
   }

   const handleValidation = (code, msg) => {
      if (code === 999) {
         setSuccess(false)
         setMessage("Please select atleast one option from the Business Area picklist.")
         setBlinkSearch("highlightFields")
      } else if (code === 123) {
         setSuccess(false)
        
         setMessage(
            "The max allowed limit for Business Area selection for Characteristics Master Report is 10 per request"
         )
         setBlinkSearch("highlightFields")
      } else if (code === 200) {
         setSuccess(true)
         setMessage(
            "Your Report will be processed in the background and will complete shortly. Please refresh the screen after few minutes"
         )
      } else if (code === 422 || code === 429 || code === 417 || code === 501) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 401) {
         notAuthorized(props.history)
      } else if (code === 423) {
         setSuccess(false)
         setMessage(
            "The maximum number of In process report requests (5) has been reached. Please submit another report later."
         )
      } else {
         setSuccess(false)
         setMessage("Report submission failed. Please contact SpecReaderExt.im@pg.com for help.")
      }
   }

   const clearMsg = () => {
      setMessage("")
   }
   const handleClearItems = () => {
      document.getElementById("view").value = "0"

      props.clearItem()
   }

   const handleInputChange = (e) => {
      e.preventDefault()
      setBlinkSearch("reportOptions")
      setMessage("")
      const textVal = textValidation(e.target.value, e.target.name)
      if (textVal.props.children || e.target.value === "") {
         const { name, value } = e.target
         setData((data) => ({
            ...data,
            [name]: value,
         }))
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         
         setData((data) => ({
            ...data,
           
          
            reportCustomName: props.templateData.reportCustomName
               ? props.templateData.reportCustomName
               : "",
            reportName: props.reportName ? props.reportName : "",
            
         }))
        setbus_areaPicklist(props.buisnessArea)
        if(props.templateBusinessArea){
         setSelectedBusinessArea(props.templateBusinessArea)
         
         }
      }
      
   }, [props.templateData, props.buisnessArea, props.templateBusinessArea])

   return (
      <React.Fragment>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Release CM Only
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  value={data.releaseCMonly}
                  checked={true}
                  disabled
               />
            </Col>
         </Form.Group>
        
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label
               column
               sm="5"
               md="5"
               className="reportLabels"
               id="impInput"
               style={{ marginTop: "100px" }}
            >
               Business Area(s)
            </Form.Label>
            <Col sm="6" md="6">
               <Row>
                  <Col className="ColGrow">
                     <Form.Label style={{ marginLeft: "15px" }}>
                        Business Area PickList
                     </Form.Label>
                     <div style={{ border: "1px solid black" }}>
                        <ListGroup className="BusinessList">{business_content}</ListGroup>
                     </div>
                  </Col>
                  <Col className="ColGrow">
                     <Button onClick={handleSelected}>{">"}</Button>
                     <Button onClick={handleSelected}>{"<"}</Button>
                  </Col>
                  <Col className="ColGrow">
                     <Form.Label style={{ marginLeft: "15px" }}>
                        Selected Business Area
                     </Form.Label>
                     <div style={{ border: "1px solid black" }}>
                        <ListGroup className="BusinessList">{selected_content}</ListGroup>
                     </div>
                  </Col>
               </Row>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Report Custom Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="reportCustomName"
                  value={data.reportCustomName}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="formGridCheckbox">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Save as Template (New Template will be created)
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  name="template"
                  value={data.template}
                  onChange={handleInputChange}
                  className="reportOptions"
               />
            </Col>
         </Form.Group>
         <Form.Row>
            <Col md="11" sm="11" align="center">
               <p
                  style={{ marginTop: "4px", fontWeight: "bold" }}
                  className={success ? "successMsg" : "alertMsg"}
               >
                  {message}
               </p>
            </Col>
         </Form.Row>
         <br />
         <ReportSubmitCharMaster
            data={data}
            handleClearItems={handleClearItems}
            handler={handleValidation}
            clearMsg={clearMsg}
         />
         <br />
      </React.Fragment>
   )
}
export default CharMasterReport
