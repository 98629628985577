import { saveAs } from "file-saver"
import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"
import { GenDocFileDownload } from "../../api/AuthService"
import { GENDOCFILE_DOWNLOAD } from "../../constant/Constants"
import CustomLink from "../commonComponents/CustomLink"

const PoaFileDownload = (props) => {
   const data = props.data.sort()
   const headerContent = JSON.parse(JSON.stringify(props.headerContent))

   var numRows = 0
   const Type = props.attributes.Type ? props.attributes.Type : ""

   const [loading, setLoading] = useState(false)
   const [show, setShow] = useState(false)
   const [errorMeg, setErrorMsg] = useState(false)

   if (data != null && data.length > 0) {
      numRows = data.length
   }

   const getHeader = (headerData = []) => {
      var keys = getKeys(headerData)

      return keys.map((key, index) => {
         if (
            key.toLowerCase() === "id" ||
            key.toLowerCase() === "link" ||
            key.toLowerCase() === "filepath" ||
            key.toLowerCase() === "gendoc" ||
            key.toLowerCase() === "file name"
         ) {
            return null
         }
         if (key.toLowerCase() === "zcsurl") {
            return <th key={key}></th>
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getKeys = (keysInformation = []) => {
      return Object.keys(keysInformation)
   }
   const getColumnKeys = (columKeyInfo) => {
      return Object.keys(columKeyInfo)
   }

   const RenderColumn = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] !== "" && props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const getRowsData = (tableData) => {
      var items = tableData
      var keys = getKeys(tableData[0])
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const RenderRow = (props) => {
      return props.keys.map((key, index) => {
         if (props.data[key] != null && typeof props.data[key] === "object") {
            var colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn data={props.data[key]} isbold={true} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access"
         ) {
            var name = props.data[key]
            return (
               <td style={{ minWidth: "9rem" }} key={index}>
                  {props.data["link"] && props.data["link"] !== "" && props.data["id"] ? (
                     <CustomLink
                        id={props.data["id"]}
                        link={props.data["link"]}
                        name={name}
                     />
                  ) : (
                     <span>{name}</span>
                  )}
               </td>
            )
         }
         if (key.toLowerCase() === "action" && Type.toLowerCase().trim() !== "poa") {
            let fileName = props.data["File Name"]
               ? props.data["File Name"]
               : props.data["Name"]

            return (
               <td key={index}>
                  {props.data[key] && fileName && props.data["filepath"] ? (
                     <button
                        onClick={() =>
                           downloadGenDocFiles(
                              fileName.trim(),
                              props.data["filepath"].trim(),
                              props.data["Format"]
                           )
                        }
                     >
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  ) : (
                     ""
                  )}
               </td>
            )
         }
         // if (key.toLowerCase() === "zcsurl" && Type.toLowerCase().trim() === "poa") {
         if (key.toLowerCase() === "zcsurl") {
            let fileName = props.data["File Name"]
               ? props.data["File Name"]
               : props.data["Name"]

            return (
               <td key={index}>
                  {props.data[key] && fileName && props.data["filepath"] ? (
                     <button
                        onClick={() =>
                           downloadGenDocFiles(
                              fileName.trim(),
                              props.data["filepath"].trim(),
                              props.data["Format"]
                           )
                        }
                     >
                        <i className="fa fa-download" aria-hidden="true"></i>
                     </button>
                  ) : (
                     ""
                  )}
               </td>
            )
         } else if (
            key.toLowerCase() === "id" ||
            key.toLowerCase() === "link" ||
            key.toLowerCase() === "filepath" ||
            key.toLowerCase() === "gendoc" ||
            key.toLowerCase() === "file name"
         ) {
            return null
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   const downloadGenDocFiles = (fileName, filePath, filetype) => {
      setLoading(true)

      let specialRegex = /[`!@#$%^&*()+={};':"\\|<>?~]/
      headerContent &&
         Object.entries(headerContent).forEach(
            ([key, val]) =>
               val &&
               typeof val !== "object" &&
               specialRegex.test(val) &&
               delete headerContent[key]
         )
      GenDocFileDownload(
         `${GENDOCFILE_DOWNLOAD}`,
         btoa(unescape(encodeURIComponent(fileName))),
         btoa(filePath),
         filetype,
         {},
         headerContent
      )
         .then((response) => {
            const fileContents = new Blob([response.data])
            saveAs(fileContents, fileName)
            setLoading(false)
         })
         .catch((error) => {
            if (error.response && error.response.status === 404) {
               setShow(true)
               setErrorMsg(`"${fileName} is not available to download.Please contact SpecReaderExt.im@pg.com for help."`)
            } else if (error.response && error.response.status === 403) {
               setShow(true)
               setErrorMsg(
                  "Integration service issue. Please contact SpecReaderExt.im@pg.com for help."
               )
            } else if (error.response && error.response.status === 422) {
               setShow(true)
               setErrorMsg(
                  "You have exceeded the limit for the day and access to any additional Part/Specification is blocked! Please wait until the limit resets tomorrow or contact SpecReaderExt.im@pg.com and request the limit be reset."
               )
            } else {
               setShow(true)
               setErrorMsg("Integration service issue. Please contact SpecReaderExt.im@pg.com for help.")
            }
            setLoading(false)
         })
   }

   if (show) {
      return (
         <Alert
            style={{ textAlign: "center", marginLeft: "8rem", marginRight: "8rem" }}
            variant="info"
            onClose={() => setShow(false)}
            dismissible
         >
            <p>{errorMeg}</p>
         </Alert>
      )
   }

   return (
      <div className="tableFixHead">
         {numRows > 0 && (
            <LoadingOverlay active={loading} spinner text="Downloading .......">
               <table
               >
                  <thead>
                     <tr>{getHeader(data[0])}</tr>
                  </thead>

                  <tbody>{getRowsData(data)}</tbody>
               </table>
            </LoadingOverlay>
         )}
      </div>
   )
}
export default PoaFileDownload
